import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Zipkin integration";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RequiredDependencies = makeShortcode("RequiredDependencies");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "zipkin-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#zipkin-integration",
        "aria-label": "zipkin integration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Zipkin integration`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#see-also"
        }}>{`See also`}</a></li>
    </ul>
    <p>{`If you want to troubleshoot latency problems in microservice architecture, you will want to use distributed
tracing system such as `}<a parentName="p" {...{
        "href": "https://zipkin.io/"
      }}>{`Zipkin`}</a>{`. It gathers timing data and shows which component is
failing or taking more time than others in a distributed environment. Armeria supports distributed tracing via
`}<a parentName="p" {...{
        "href": "https://github.com/openzipkin/brave/"
      }}>{`Brave`}</a>{`, which is a Java tracing library compatible with
`}<a parentName="p" {...{
        "href": "https://zipkin.io/"
      }}>{`Zipkin`}</a>{`. Let's find out how to use it to trace requests.`}</p>
    <p>{`First, You need the `}<inlineCode parentName="p">{`armeria-brave`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-brave'
    }]} mdxType="RequiredDependencies" />
    <p>{`Then, you need to create the `}<inlineCode parentName="p">{`HttpTracing`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`    import com.linecorp.armeria.common.brave.RequestContextCurrentTraceContext;

    import brave.Tracing;
    import brave.http.HttpTracing;
    import zipkin2.reporter.brave.AsyncZipkinSpanHandler;

    AsyncZipkinSpanHandler spanHandler = ...
    Tracing tracing = Tracing.newBuilder()
                             .localServiceName("myService")
                             .currentTraceContext(RequestContextCurrentTraceContext.ofDefault())
                             .addSpanHandler(spanHandler)
                             .build();
    HttpTracing httpTracing = HttpTracing.create(tracing);
`}</code></pre>
    <p>{`Please note that we specified `}<a parentName="p" {...{
        "href": "type://RequestContextCurrentTraceContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/brave/RequestContextCurrentTraceContext.html"
      }}>{`type://RequestContextCurrentTraceContext`}</a>{`. It stores the trace context into a
`}<a parentName="p" {...{
        "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
      }}>{`type://RequestContext`}</a>{` and loads the trace context from the `}<a parentName="p" {...{
        "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
      }}>{`type://RequestContext`}</a>{` automatically. Because of that,
we don't need to use a thread local variable which can lead to unpredictable behavior in asynchronous
programming. If you want to send timing data to the span collecting server, you should specify `}<inlineCode parentName="p">{`spanHandler`}</inlineCode>{`.
For more information about the `}<inlineCode parentName="p">{`spanHandler`}</inlineCode>{`, please refer to
`}<a parentName="p" {...{
        "href": "https://github.com/openzipkin/zipkin-reporter-java/tree/master/brave"
      }}>{`Zipkin Reporter Brave`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://github.com/openzipkin/brave-example/tree/master/armeria"
      }}>{`the fully working example`}</a>{`.`}</p>
    <p>{`Now, you can specify `}<a parentName="p" {...{
        "href": "type://BraveService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/brave/BraveService.html"
      }}>{`type://BraveService`}</a>{` using `}<a parentName="p" {...{
        "href": "/docs/server-decorator"
      }}>{`Decorating a service`}</a>{` with the
`}<inlineCode parentName="p">{`HttpTracing`}</inlineCode>{` you just built:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`    import com.linecorp.armeria.common.HttpResponse;
    import com.linecorp.armeria.server.Server;
    import com.linecorp.armeria.server.brave.BraveService;

    Tracing tracing = ...
    Server server = Server.builder()
                          .http(8081)
                          .service("/", (ctx, res) -> HttpResponse.of(200))
                          .decorator(BraveService.newDecorator(httpTracing))
                          .build();
`}</code></pre>
    <p>{`If the requests come to Armeria server and go to another backend, you can trace them using
`}<a parentName="p" {...{
        "href": "type://BraveClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/brave/BraveClient.html"
      }}>{`type://BraveClient`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`    import com.linecorp.armeria.client.WebClient;
    import com.linecorp.armeria.client.brave.BraveClient;
    import com.linecorp.armeria.server.brave.BraveService;

    Tracing tracing = ...
    WebClient client = WebClient
            .builder("https://myBackend.com")
            .decorator(BraveClient.newDecorator(httpTracing.clientOf("myBackend")))
            .build();

    Server server = Server.builder()
                          .http(8081)
                          .service("/", (ctx, res) -> client.get("/api"))
                          .decorator(BraveService.newDecorator(httpTracing))
                          .build();
`}</code></pre>
    <p>{`Please note that our `}<inlineCode parentName="p">{`HttpTracing`}</inlineCode>{` instance used the same `}<inlineCode parentName="p">{`Tracing`}</inlineCode>{` instance when we
create `}<a parentName="p" {...{
        "href": "type://BraveClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/brave/BraveClient.html"
      }}>{`type://BraveClient`}</a>{` and `}<a parentName="p" {...{
        "href": "type://BraveService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/brave/BraveService.html"
      }}>{`type://BraveService`}</a>{`. Otherwise, there might be problems if the instances are not
configured exactly the same.
In the same manner, you can use the `}<inlineCode parentName="p">{`Tracing`}</inlineCode>{` instance with any
`}<a parentName="p" {...{
        "href": "https://github.com/openzipkin/brave/tree/master/instrumentation"
      }}>{`Brave instrumentation libraries`}</a>{`.
For example, you can use it with `}<a parentName="p" {...{
        "href": "https://kafka.apache.org/"
      }}>{`Kafka`}</a>{` producer:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`    import org.apache.kafka.clients.producer.KafkaProducer;
    import org.apache.kafka.clients.producer.Producer;
    import org.apache.kafka.clients.producer.ProducerRecord;

    import brave.kafka.clients.KafkaTracing;

    Tracing tracing = ...
    KafkaTracing kafkaTracing = KafkaTracing.newBuilder(tracing)
                                            .remoteServiceName("backend")
                                            .writeB3SingleFormat(true)
                                            .build();

    Properties props = new Properties();
    props.put("bootstrap.servers", "https://myKafka.com");
    props.put("acks", "all");
    ...

    Producer<String, String> kafkaProducer = kafkaTracing.producer(new KafkaProducer<>(props));

    Server server = Server.builder()
                          .http(8081)
                          .service("/", (ctx, req) -> {
                              kafkaProducer.send(new ProducerRecord<>("test", "foo", "bar"));
                              return HttpResponse.of(200);
                          })
                          .decorator(BraveService.newDecorator(tracing))
                          .build();
`}</code></pre>
    <p>{`This will trace all the requests sent from the client to the above example server to
`}<a parentName="p" {...{
        "href": "https://kafka.apache.org/"
      }}>{`Kafka`}</a>{`, and report timing data using the `}<inlineCode parentName="p">{`spanHandler`}</inlineCode>{` you specified.
The following screenshot shows a trace of a request:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.62576687116564%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABqUlEQVQoz2WP2W4TQRBF58v5Gd74CSTEA5DkIZEc4niJl9l7nV6mx9jhoGkrUSQejqpuqfre6kL2LWK/pl8vcOWGs6qZ+mOuJ1nxZ9aizMx96g64aouvtrjdI75c47sS3ZYE7yi0FNhySzg+4w5LxOoh4/ZPjNUqY7cL3P43qVoRj0vCcYnfPzJu74m7BWN3wDQHrNEUsmuQSnEG0vmVOJ04Xf6yrDQ/Vj23L5qfa8HNVuX+10bm+d3OcLuzfF923GwET6WhFJZi9AOqKUnTRIoRoRR6cDRdz77uEUN8p7chI4bxWm1g3RgeXno6PXC5nCm06LLhfKW0A923L7SfP1HdfSVayWtQjLohmZazk1y8IumGk+05+6ueTEeaCQOFlT26KVHaYIxF1zva53u0aEmnibZrkUrivMcFT93U9FIQYmRwA1VTU9UVPnjGNFKYN0Nj0FojtEY7j48xL2mjiWPMyyEG7GBznbWxJuO8u+6Ms6H4eKGhaWusNaQpEdOY6/x4Zg6YH3+czf1HXVglMF2NNjYbzhcprQgh5NS39BwwXr/5ZvIfKfEPE4umWlvgQKIAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "zipkin 1",
          "title": "zipkin 1",
          "src": "/static/4c0eed158c7ca619c37d255151184f4a/a6d36/zipkin_1.png",
          "srcSet": ["/static/4c0eed158c7ca619c37d255151184f4a/222b7/zipkin_1.png 163w", "/static/4c0eed158c7ca619c37d255151184f4a/ff46a/zipkin_1.png 325w", "/static/4c0eed158c7ca619c37d255151184f4a/a6d36/zipkin_1.png 650w", "/static/4c0eed158c7ca619c37d255151184f4a/e548f/zipkin_1.png 975w", "/static/4c0eed158c7ca619c37d255151184f4a/3c492/zipkin_1.png 1300w", "/static/4c0eed158c7ca619c37d255151184f4a/cc40b/zipkin_1.png 2394w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "see-also",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-also",
        "aria-label": "see also permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/openzipkin/brave-example/tree/master/armeria"
        }}>{`Brave example Armeria`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/openzipkin/brave-example/tree/master/armeria-kafka"
        }}>{`Brave example Armeria with Kafka`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      